import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { ContentSafeArea } from '../globalComponents';
import Logo from '../logo';

import {
  HeaderContainer,
  HeaderBackground,
  NavBar,
  NavBarToggle,
  NavBarCollapse,
  NavLink,
} from './styles.js'

export default function Header(props){

  const [isScrolled, setIsScrolled] = useState();

  const listenScrollEvent = (event) => {
      setIsScrolled(window.scrollY > 73)
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    setIsScrolled(window.scrollY > 73)
  }, []);

  return (
    <HeaderContainer scrolled={isScrolled} isFixed={props.isFixed}>
      <HeaderBackground scrolled={isScrolled} isFixed={props.isFixed}/>
      <ContentSafeArea>
        <NavBar collapseOnSelect expand="md">
          <Logo isScrolled={isScrolled}/>
          <NavBarToggle aria-controls="responsive-navbar-nav"/>
          <NavBarCollapse id="responsive-navbar-nav">
              <NavLink forwardedAs={Link} activeClassName='active' to="/"> Home </NavLink >
              <NavLink forwardedAs={Link} activeClassName='active' to="/produtos"> Produtos </NavLink >
              <NavLink forwardedAs={Link} activeClassName='active' to="/servicos"> Serviços </NavLink >
              <NavLink forwardedAs={Link} activeClassName='active' to="/sobre"> Sobre </NavLink >
              <NavLink forwardedAs={Link} activeClassName='active' to="/contato"> Contato </NavLink >
          </NavBarCollapse>
        </NavBar>
      </ContentSafeArea>
    </HeaderContainer>
  )
}
