import React from 'react';
import Logo from '../logo';
import { Instagram, FacebookSquare as Facebook, Linkedin, Whatsapp } from '@styled-icons/fa-brands';
import { Telephone } from '@styled-icons/foundation/Telephone';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import {
  FooterDiv,
  FooterSection,
  FooterColumn,
  FooterSafeArea,
  SocialLinks,
  LogoContainer,
  FooterFooter,
} from './styles';

// const iframe = '<div style="width: 100%"><iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=R.%20Tom%C3%A1s%20Fonseca,%201205%20-%20Comendador%20Soares,%20Nova%20Igua%C3%A7u%20-%20RJ,%2026031-510+(Vivagraf)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>';

export default function Footer() {
  return (
    <FooterDiv>
      <FooterSection>
        <FooterSafeArea>
          <FooterColumn>
            <LogoContainer>
              <Logo fullWidth={true} linkToHome={false}/>
            </LogoContainer>
            <SocialLinks>
              <OutboundLink href="https://www.instagram.com/vivagraf/" target="_blank" rel="noreferrer">
                <Instagram size={36}/>
              </OutboundLink>
              <OutboundLink href="https://www.facebook.com/vivagrafimpressaodigital/" target="_blank" rel="noreferrer">
                <Facebook size={36}/>
              </OutboundLink>
              <OutboundLink href="https://www.linkedin.com/company/vivagraf-digital/" target="_blank" rel="noreferrer">
                <Linkedin size={36}/>
              </OutboundLink>
            </SocialLinks>
          </FooterColumn>
          <FooterColumn centerContent>
            {/* https://www.convertte.com.br/gerador-link-whatsapp/ */}
            <OutboundLink href="https://api.whatsapp.com/send?phone=5521964781982&text=Ol%C3%A1%2C%20gostaria%20de%20fazer%20um%20or%C3%A7amento" target="_blank" rel="noreferrer">
              <Whatsapp size={46} />
              <div>
                <span>Whatsapp</span>
                <span>(21) 96478-1982</span>
              </div>
            </OutboundLink>
            <OutboundLink href="tel:212667-5995" target="_blank" rel="noreferrer">
              <Telephone size={36} />
              <span>(21) 2667-5995</span>
            </OutboundLink>
            <OutboundLink href="tel:212797-4613" target="_blank" rel="noreferrer">
              <Telephone size={36} />
              <span>(21) 2797-4613</span>
            </OutboundLink>
            {/* <OutboundLink href="tel:212667-5995" target="_blank" rel="noreferrer">
              <Telephone size={36} />
              <span>(21) 2667-5995</span>
            </OutboundLink> */}
          </FooterColumn>
          <FooterColumn>
          <OutboundLink href="https://goo.gl/maps/4tjZ3D5kf5bnf5fC8" target="_blank" rel="noreferrer">
            <div>
              <span>Rua Tomas Fonseca, 1205</span>
              <span>Cerâmica, Nova Iguaçu - RJ</span>
            </div>
          </OutboundLink>
          </FooterColumn>
        </FooterSafeArea>
      </FooterSection>
      {/* <FooterSection dangerouslySetInnerHTML={ {__html:  iframe}}>
      </FooterSection> */}
      <FooterFooter>
        <span>Copyright @ 2023 Vivagraf. Todos os direitos reservados.</span>
      </FooterFooter>
    </FooterDiv>
  )
}
