import React from "react"
import { StyledLink } from '../globalComponents';
import LogoSVG from '../../images/svg/vivagrafFullLogo.svg';

import { LogoContainer } from './styles';

export default function Logo({isScrolled = false, fullWidth = false, linkToHome = true}) {

  if(linkToHome){
    return (
      <StyledLink to='/'>
        <LogoContainer isScrolled={isScrolled} fullWidth={fullWidth} >
          <LogoSVG alt='Vivagraf Impressão Digital' />
        </LogoContainer>
      </StyledLink>
    )
  } else {
    return (
      <LogoContainer isScrolled={isScrolled} fullWidth={fullWidth} >
        <LogoSVG alt='Vivagraf Impressão Digital' />
      </LogoContainer>
    )
  }

}
