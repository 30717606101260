import styled from 'styled-components';

export const LayoutContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  min-width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const PageContent = styled.div`
  margin: 0;
  /* padding: 1rem;
  max-width: 1200px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* height: 3000px; */
  overflow: hidden;
  align-items: center;
`;
