import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "../header"
import Footer from "../footer"
import WhatsappFixed from '../whatsappFixed';
import { LayoutContainer, PageContent } from './styles';

export default function Layout({ children, isHeaderFixed = false }) {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <LayoutContainer>
      <Header isFixed={isHeaderFixed}/>
      <PageContent>
        {children}
      </PageContent>
      <Footer />
      <WhatsappFixed />
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
