import styled, { css } from 'styled-components';
import { Nav, Navbar } from 'react-bootstrap'
import { StyledLink } from '../globalComponents';

export const HeaderContainer = styled.header`
  z-index: 99;
  position: ${props => props.isFixed ? 'fixed' : '-webkit-sticky'}; /* for safari */
  position: ${props => props.isFixed ? 'fixed' : 'sticky'};
  padding: 0px;
  top: 0;
  display: flex;
  justify-content: center;
  min-width: 100vw;

  ${StyledLink}{
    flex: 1;
  }
`;

export const HeaderBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.991353) 6.67%, rgba(255, 255, 255, 0.96449) 13.33%, rgba(255, 255, 255, 0.91834) 20%, rgba(255, 255, 255, 0.852589) 26.67%, rgba(255, 255, 255, 0.768225) 33.33%, rgba(255, 255, 255, 0.668116) 40%, rgba(255, 255, 255, 0.557309) 46.67%, rgba(255, 255, 255, 0.442691) 53.33%, rgba(255, 255, 255, 0.331884) 60%, rgba(255, 255, 255, 0.231775) 66.67%, rgba(255, 255, 255, 0.147411) 73.33%, rgba(255, 255, 255, 0.0816599) 80%, rgba(255, 255, 255, 0.03551) 86.67%, rgba(255, 255, 255, 0.0086472) 93.33%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.7);
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);

  ${props => props.isFixed ?
  css`
    opacity: 1;
    transition: opacity .4s ease-out .2s;
    ${props =>
    props.scrolled ?
    css`
      opacity: 1;
      transition: opacity .4s ease-out .2s;
    ` : ``
    }
  `
  : css`
  opacity: 1;
  transition: opacity .4s ease-out .2s;
  ` }
`;

export const NavBar = styled(Navbar)`
  padding: 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const NavBarToggle = styled(Navbar.Toggle)`
  border: 0;
  padding: 0;
`;

export const NavBarCollapse = styled(Navbar.Collapse)`
  justify-content: flex-end;
`;

export const NavLink = styled(Nav.Link)`
  color: #333;
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
  margin: 0;

  &:hover, &.active {
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
  }
`;
