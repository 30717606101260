import styled, { css } from 'styled-components';

export const LogoContainer = styled.div`
  ${props =>
    props.fullWidth ?
    css `
      width: 100%;
    ` : css`
      ${props =>
        props.isScrolled ?
          css`
            width: 50%;
            height: auto;
            margin: 0.7rem 0rem;
            transition: margin .4s ease-out .2s, width .4s ease-out .2s;
            @media(min-width: 600px) {
              width: 45%;
              margin: 0.85rem;
            }
          `  : css`
            width: 50%;
            height: auto;
            margin: 0.7rem 0rem;
            transition: margin .4s ease-out .2s, width .4s ease-out .2s;
            @media(min-width: 600px) {
              width: 65%;
            }
          `
      }
    `
  }

  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;

  svg {
    width: 100%;
    height: 100%;
    max-height: 60px;
  }
`
