import React from 'react';
import {Whatsapp} from '@styled-icons/fa-brands';

import {
  Container
} from './styles';

export default function WhatsappFixed() {
  return(
    <Container href='https://api.whatsapp.com/send?phone=5521964781982&text=Ol%C3%A1%2C%20eu%20gostaria%20de%20pedir%20um%20or%C3%A7amento!' target="_blank" rel="noreferrer">
      <Whatsapp style={{height: '80%'}}/>
    </Container>
  )
}
