import styled from 'styled-components';
import { Link } from 'gatsby';

export const ContentSafeArea = styled.div`
  margin: 0;
  padding: 0 1.3rem;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* border: 1px solid; */
`;

export const Button = styled.button`
  background: linear-gradient(95.56deg, #D9272A 33.63%, #FF2E31 67.02%);
  color: white;
  font-weight: bold;
  padding: 15px 30px;
  text-align: center;
  width: fit-content;
  border-radius: 10px;
  border: none;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #fff;
    letter-spacing: 1pt;
    transition: ease-in-out 0.3s letter-spacing;
  }
`

// export const VivagrafLogo = styled(Logo)``;

export const StyledLink = styled(Link)``;
