import styled from 'styled-components';
import { ContentSafeArea } from '../globalComponents';

export const FooterDiv = styled.footer`
  margin-bottom: 0;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: inset 0px 6px 24px 6px rgba(0, 0, 0, 0.1);
`;

export const FooterSection = styled.div`
  min-height: 250px;
  display: flex;
  justify-content: center;
`;

export const FooterFooter = styled.div`
  display: flex;
  justify-content: center;

  span {
    font-size: 0.8em;
    margin: 0.5em;
  }
`;

export const LogoContainer = styled.div`
  width:60%;
`

export const FooterColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 1rem;
  align-items: ${props => props.centerContent ? 'center' : 'left'};
  align-items: center;
  justify-content: center;
  min-width: 275px;

  @media(max-width: 871px) {
    align-items: center;
    margin-top: 3rem;
  }

  a {
    color: #000;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;

    :hover {
      color: #f00;
    }

    div:nth-of-type(1) {
        margin-bottom: 1rem;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      spam {
        margin: 0;
        padding: 0;
      }
    }
  }
`;

export const FooterSafeArea = styled(ContentSafeArea)`
  flex-direction: row;
  flex-wrap: wrap;

  @media(max-width: 600px) {
    flex-direction: column;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.6rem;
  justify-content: center;
  align-items: center;

  a {
    color: #000;
    &:hover {
      color: #f00;
    }
  }
`;
