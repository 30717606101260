import styled from 'styled-components';

export const Container = styled.a`
  position: fixed;
  bottom: 100px;
  right: 0px;
  width: 75px;
  height: 70px;
  background: linear-gradient(90deg, #1BD741 0%, #1EF84A 100%);
  border-radius: 15px 0 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  z-index: 999;
  &:hover{
    color: #eee;
  }
`;
